// import Lenis from '@studio-freight/lenis'

// const lenis = new Lenis({
//     duration: 1,
//     easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
//     direction: "vertical",
//     gestureDirection: "vertical",
//     smooth: true,
//     smoothTouch: false,
//     touchMultiplier: 2,
// });
//
// function raf(time) {
//     lenis.raf(time);
//     requestAnimationFrame(raf);
// }
//
// requestAnimationFrame(raf);

// const lenis = new Lenis()
//
// lenis.on('scroll', (e) => {
//     console.log(e)
// })
//
// function raf(time) {
//     lenis.raf(time)
//     requestAnimationFrame(raf)
// }
//
// requestAnimationFrame(raf)